<template>
  <SfLoader v-if="loadingProducts" />
  <div
    class="cart-product-carousel-wrapper"
    v-else-if="products && products.length > 0"
  >
    <ProductCarousel :products="products" :title="title" />
  </div>
</template>

<script>
import { defineComponent, useFetch } from '@nuxtjs/composition-api';
import { useCategoryPage } from '~/composables';
import ProductCarousel from '~/components/General/ProductCarousel.vue';
import { SfLoader } from '@storefront-ui/vue';

export default defineComponent({
  name: 'CategoryProductsCarousel',
  components: { ProductCarousel, SfLoader },
  props: {
    carouselCategoryId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'Complete your purchase',
    },
  },
  setup(props) {
    const CART_CAROUSEL_CATEGORY_ID = props.carouselCategoryId;
    const { loadProducts, products, loadingProducts } = useCategoryPage(
      CART_CAROUSEL_CATEGORY_ID
    );

    useFetch(async () => {
      await loadProducts();
    });

    return { products, loadingProducts };
  },
});
</script>
